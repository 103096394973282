// Generated by scripts/stats/update_language_stats.js@inventaire-i18n
export default {
  ar: {
    completion: 62,
    defaultRegion: 'ar_AR'
  },
  bn: {
    completion: 68,
    defaultRegion: 'bn_BN'
  },
  ca: {
    completion: 45,
    defaultRegion: 'ca_CA'
  },
  cs: {
    completion: 46,
    defaultRegion: 'cs_CS'
  },
  da: {
    completion: 53,
    defaultRegion: 'da_DK'
  },
  de: {
    completion: 98,
    defaultRegion: 'de_DE'
  },
  el: {
    completion: 6,
    defaultRegion: 'el_EL'
  },
  en: {
    completion: 100,
    defaultRegion: 'en_US'
  },
  eo: {
    completion: 17,
    defaultRegion: 'eo_EO'
  },
  es: {
    completion: 74,
    defaultRegion: 'es_ES'
  },
  fi: {
    completion: 7,
    defaultRegion: 'fi_FI'
  },
  fr: {
    completion: 99,
    defaultRegion: 'fr_FR'
  },
  hu: {
    completion: 12,
    defaultRegion: 'hu_HU'
  },
  id: {
    completion: 66,
    defaultRegion: 'id_ID'
  },
  it: {
    completion: 87,
    defaultRegion: 'it_IT'
  },
  ja: {
    completion: 49,
    defaultRegion: 'ja_JP'
  },
  nb: {
    completion: 9,
    defaultRegion: 'nb_NB'
  },
  nl: {
    completion: 59,
    defaultRegion: 'nl_NL'
  },
  pa: {
    completion: 7,
    defaultRegion: 'pa_PA'
  },
  pl: {
    completion: 57,
    defaultRegion: 'pl_PL'
  },
  pt: {
    completion: 12,
    defaultRegion: 'pt_PT'
  },
  ro: {
    completion: 93,
    defaultRegion: 'ro_RO'
  },
  ru: {
    completion: 69,
    defaultRegion: 'ru_RU'
  },
  sk: {
    completion: 16,
    defaultRegion: 'sk_SK'
  },
  sv: {
    completion: 97,
    defaultRegion: 'sv_SE'
  },
  th: {
    completion: 4,
    defaultRegion: 'th_TH'
  },
  tr: {
    completion: 50,
    defaultRegion: 'tr_TR'
  },
  uk: {
    completion: 72,
    defaultRegion: 'uk_UK'
  }
} as const